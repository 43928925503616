import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ResearchIndex from "../components/Research/ResearchIndex"

const ResearchListTemplate = ({ data, pageContext, path, location }) => {
  const {
    allPrismicResearch: { edges: researchesData },
  } = data

  const { basePath, categories } = pageContext

  const researches = researchesData.map(research => research.node)

  const normalizedCats = categories.map(cat => ({
    path: `${basePath}/${cat.uid}`,
    name: cat.document.data.name,
    color: cat.document.data.color,
  }))

  const categoriesList = [
    { name: "Everything", path: basePath },
    ...normalizedCats,
  ]

  if (!researches) return null

  return (
    <Layout location={location}>
      <Seo pathname={location.pathname} title="Research papers" />
      <ResearchIndex
        location={location}
        data={researches}
        basePath={basePath}
        path={path}
        pageContext={pageContext}
        categories={categoriesList}
      />
    </Layout>
  )
}

ResearchListTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ResearchListTemplate

export const data = graphql`
  query ($skip: Int!) {
    allPrismicResearch(
      sort: {fields: data___date, order: DESC}
      skip: $skip
      limit: 3
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            authors {
              author {
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            subtitle
            category {
              uid
            }
            cover {
              url
              gatsbyImageData(layout: CONSTRAINED, width: 310)
            }
          }
        }
      }
    }
  }
`
